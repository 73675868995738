import { api } from "./";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "users";

service.model = {
  updated_at: "",
  role_id: 1,
  active: false,
  name: "",
  email: "",
  password: "",
};

service.exportEntrepreneurs = async function (filters) {
  let result = null;
  await api
    .post(`/api/${endpoint}/export`, filters, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getUsers = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}/admins`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getEntrepreneurs = async function (
  page = 1,
  limit = 10,
  currentSortItem,
  filterList
) {
  let result = null;
  let url = "";
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;

      if (filterList[key] === 1 || filterList[key] === 2)
        filterParams += `&filter[${key}]=${
          filterList[key] === 1 ? true : false
        }`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/entrepreneurs?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/entrepreneurs?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });

  console.log("-------------------------------------------> service", result);
  return result;
};

service.getEntrepreneursById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/entrepreneurs/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getUserById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postUser = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}/`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.insertUser = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}/admin`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateUser = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}/admin`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateEntrepreneurs = async function (data) {
  let result = null;
  await api
    .put(`/api/users/admin-entrepreneurs`, data, config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.deleteUser = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteUserLGPD = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/all/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postRecoverPass = async function (data) {
  let result = null;
  await api
    .post(`/api/recover-pass/`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.checkHashIsValid = async function (data) {
  let result = null;
  await api
    .post(`/api/recover-pass/verify-hash`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getRecoverPassHash = async function (data) {
  let result = null;
  await api
    .get(`/api/recover-pass/${data}`)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.putUserPass = async function (data) {
  let result = null;
  await api
    .put(`/api/recover-pass/change-pass`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postConfirmEmail = async function (data) {
  let result = null;
  await api
    .post(`/api/users/confirm-email`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.putUserPassOld = async function (data) {
  let result = null;
  await api
    .put(`/api/users/password`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

//atualizarBD
service.getUsersCripto = async function () {
  let result = null;
  await api
    .get(`/api/update-db/get-users`)
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getProfilesCripto = async function () {
  let result = null;
  await api
    .get(`/api/update-db/get-profiles`)
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSolicitationsCripto = async function () {
  let result = null;
  await api
    .get(`/api/update-db/get-solicitations`)
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postSolicitationCripto = async function (data) {
  let result = null;
  await api
    .post(`/api/update-db/update-solicitation`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postProfileCripto = async function (data) {
  let result = null;
  await api
    .post(`/api/update-db/update-profile`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postUserCripto = async function (data) {
  let result = null;
  await api
    .post(`/api/update-db/update-users`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export default service;
