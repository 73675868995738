import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const defaultIsForbidden = (auth) => (roles) => roles && !roles.has(auth?.user?.role);
const defaultIsAuthenticated = (auth) => Boolean(auth.token);
const join = (setA) => (setB) => new Set([...setA, ...setB]);

export const RenderRoutes = (props) => {
    const {
        routesConfig,
        auth,
        location,
        ForbiddenComponent,
        SuspenseFallback,
        isAuthenticated = defaultIsAuthenticated,
        isForbidden = defaultIsForbidden,
        loginPagePathname,
        homePagePathname,
        parentRoles,
    } = props;

    const isForbiddenAuth = isForbidden(auth);

    return (
        <Switch>
            {routesConfig.map((route, index) => {
                const { path, component: MaybeComponent, children = [], componentProps } = route;
                const Component = MaybeComponent || React.Fragment;

                return (
                    <Route key={index} path={path} exact={!children.length} render={(renderProps) => {
                        if (!isAuthenticated(auth) && !!parentRoles) {
                            return <Redirect to={{ pathname: loginPagePathname }} />;
                        }

                        const componentPropsComposed = { ...renderProps, route: children, ...componentProps };

                        const joinedRoles = !parentRoles ? route.roles : join(parentRoles)(new Set(route.roles));

                        if (children.length === 0 && joinedRoles && isForbiddenAuth(joinedRoles)) {
                            return <ForbiddenComponent />;
                        }

                        return (
                            <Suspense fallback={SuspenseFallback}>
                                <Component {...componentPropsComposed}>
                                    {children.length > 0 && <RenderRoutes {...props} routesConfig={children} parentRoles={joinedRoles} />}
                                </Component>
                            </Suspense>
                        );
                    }} />
                );
            })}
            <Route render={() => (
                isAuthenticated(auth) ? <Redirect to={{ pathname: homePagePathname, state: { from: location } }} /> :
                    <Redirect to={{ pathname: loginPagePathname, state: { from: location } }} />
            )} />

        </Switch>

    );
};
