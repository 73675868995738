import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "creditOperation";

service.getDataClient = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/borrower`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getAllOperation = async function (page = 1, limit = 10, params) {
  let result = null;
  let url = `/api/${endpoint}`;

  const queryParams = [`page=${page}`, `limit=${limit}`];

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`);
    queryParams.push(...filterParams);
  }

  const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
  url = `/api/${endpoint}${queryString}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response ? error.response.status : 500,
      };
    });

  return result;
};

service.getAllOperationCession = async function (page = 1, limit = 10, params) {
  let result = null;
  let url = `/api/${endpoint}/installments/cession`;

  const queryParams = [`page=${page}`, `limit=${limit}`];

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`);
    queryParams.push(...filterParams);
  }

  const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
  url = `/api/${endpoint}/installments/cession${queryString}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response ? error.response.status : 500,
      };
    });

  return result;
};
service.getDataOperation = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editDataOperation = async function (id, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: error.response.data,
        error: true,
        cause: error.response.data?.cause,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getGeneralInformation = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/management`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getComplementInformation = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/complement`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editComplementInformation = async function (id, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/complement`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getFilterOperations = async function (
  id,
  page,
  limit,
  currentSortItem,
  filterList
) {
  let result = null;
  let url = "";
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/borrower/${id}/?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/borrower/${id}/?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getOtherInformations = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/others`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getPaymentSchedule = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/timeline`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getContractCCB = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contract/ccb`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.generateContractMPO = async function (id, message) {
  let result = null;
  let url = "";

  const body = {
    message,
  };

  url = `/api/${endpoint}/${id}/contract/mpo`;

  await api
    .post(url, body, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: error.response.data,
        error: true,
        cause: error.response.data?.cause,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getContractMPO = async function (id, body) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contract/mpo`;

  await api
    .get(url, body, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: error.response.data,
        error: true,
        cause: error.response.data?.cause,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getHistoric = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/messages`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postHistoric = async function (id, data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${id}/messages`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getOperationSelect = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/borrower/${id}/active`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getOperationByDate = async function (date, bankId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/banks/${bankId}/remessas?date=${date.toISOString()}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getOperationWithAssignedInstallmentsByAssignmentBankAccountIdAndDate =
  async function (date, assignmentBankAccountId) {
    let result = null;
    let url = "";

    url = `/api/${endpoint}/assignment-bank-accounts/${assignmentBankAccountId}/operations?date=${date.toISOString()}`;

    await api
      .get(url, config())
      .then((res) => {
        result = { data: res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = { data: null, error: true, type: error };
      });
    return result;
  };

service.postInstallmentAssignments = async function (creditOperationId, data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${creditOperationId}/installment-assignments`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getCasualtiesSeletivas = async function (id, borrower) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/installments/${borrower ? borrower : ""}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getCreditOperationInstallmentsAvailableForAssignment = async function (
  creditOperationId
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${creditOperationId}/assignable-installments`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getFilterCasualtiesSeletivas = async function (
  id,
  page,
  limit,
  borrower
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${id}/installments/${
    borrower ? borrower : ""
  }?page=${page}&limit=${limit}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editCasualtiesSeletivas = async function (id, installlmentId, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/installments/${installlmentId}/title`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.sendCasualties = async function (id, credit_operationId, status) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/installments/${credit_operationId}`;

  const data = {
    status,
  };

  await api
    .patch(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getInstallmentsDetails = async function (
  credit_operationId,
  installmentsId
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/details`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getAmortization = async function (
  credit_operationId,
  installmentsId,
  selectedDate
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/amortization-calc`;

  let body = {
    startDate: selectedDate,
  };

  await api
    .post(url, body, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getInstallments = async function (
  credit_operationId,
  installmentsId,
  page = 1,
  limit = 10
) {
  let result = null;
  let url = "";

  const params = {
    page,
    limit,
  };

  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/all-parcial`;

  await api
    .get(
      url,
      {
        params: params,
      },
      config()
    )
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postAmortization = async function (
  credit_operationId,
  installmentsId,
  data
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/amortization`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postBaixaParcial = async function (
  credit_operationId,
  installmentsId,
  data
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/details`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.makeEstorno = async function (
  credit_operationId,
  installmentsId,
  parcialInstallmentId
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/installments/${installmentsId}/details/${parcialInstallmentId}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
      console.log(res);
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getIssueBill = async function (credit_operationId, installlmentId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/boleto/${installlmentId}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.sendCompletBill = async function (credit_operationId, data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/boleto/carne`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getIssueBill = async function (credit_operationId, installlmentId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/boleto/${installlmentId}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getTitles = async function (credit_operationId, page, limit) {
  let result = null;
  let url = "";

  const params = {
    page,
    limit,
  };
  url = `/api/${endpoint}/${credit_operationId}/installment/repurchase`;

  await api
    .get(
      url,
      {
        params,
      },
      config()
    )
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getRepurchaseCount = async function (credit_operationId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/installment/repurchase/count`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postRepurchase = async function (
  credit_operationId,
  installmentId,
  data
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/installment/${installmentId}/repurchase`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getExpiredInstallments = async function (credit_operationId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${credit_operationId}/expired-installments`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteRepurchase = async function (credit_operationId, installmentId) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${credit_operationId}/installment/${installmentId}/repurchase`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getOperationDisbursement = async function (creditOperationId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${creditOperationId}/disbursement`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.saveOperationDisbursement = async function (creditOperationId, data) {
  let result = null;
  let url = "";

  console.log("Data:", data);
  const formattedData = Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key] || null;
    return acc;
  }, {});
  console.log("Formatted data:", formattedData);

  url = `/api/${endpoint}/${creditOperationId}/disbursement`;

  await api
    .patch(url, formattedData, config())
    .then((res) => {
      result = { data: res.data, error: false };
    })
    .catch((error) => {
      if (error.status === 400) {
        result = { data: null, error: true, type: error };
      } else {
        throw error;
      }
    });
  return result;
};

service.getRepurchasableInstallments = async function (
  borrowerId,
  creditOperationId,
  page = 1,
  limit = 10,
  filterList
) {
  let result = null;
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;
    }
  }

  const url = `/api/${endpoint}/${creditOperationId}/installments/repurchasable?page=${page}&limit=${limit}${filterParams}`;
  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getInstallmentsReverseCession = async function (creditOperationId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${creditOperationId}/installments/reverse-cession`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.reverseCession = async function (listIds) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/installments/reverse-cession/`;

  await api
    .delete(url, { data: listIds, ...config() })
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
      console.log(res);
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};
export default service;
