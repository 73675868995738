import * as Icon from "@ant-design/icons";
import { RoleGroups } from "auth/Roles";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    access: RoleGroups.AllPanelUsers,
    path: `${APP_PREFIX_PATH}/home`,
    title: "Dashboard",
    icon: Icon.HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const solicitationsNavTree = [
  {
    key: "solicitations",
    access: RoleGroups.AllPanelUsers,
    title: "Solicitações",
    submenu: [
      {
        key: "pending",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/solicitations/pending`,
        title: "Solicitações pendentes",
        icon: Icon.ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "all-solicitations",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/solicitations`,
        title: "Todas as solicitações",
        icon: Icon.UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "simulations",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/simulations`,
        title: "Simulações",
        icon: Icon.MobileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "entrepreneurs",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/entrepreneurs`,
        title: "Empreendedores",
        icon: Icon.UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const operationsNavTree = [
  {
    key: "operations",
    access: RoleGroups.AllPanelUsers,
    title: "Operações",
    submenu: [
      {
        key: "allOperations",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/allOperations`,
        title: "Todas as operações",
        breadcrumb: false,
        icon: Icon.QuestionCircleOutlined,
        submenu: [],
      },
      {
        key: "clientOperation",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/clientOperation`,
        title: "Clientes",
        breadcrumb: false,
        icon: Icon.UserOutlined,
        submenu: [],
      },

      {
        key: "company",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/company-operation`,
        title: "Empresa",
        breadcrumb: false,
        icon: Icon.LayoutOutlined,
        submenu: [],
      },
      {
        key: "messages",
        access: RoleGroups.AllPanelUsers,
        path: `${APP_PREFIX_PATH}/messages`,
        title: "Disparo de SMS e Email",
        breadcrumb: false,
        icon: Icon.MessageOutlined,
        submenu: [],
      },
    ],
  },
];

const formSolicitationsNavTree = [
  {
    key: "solicitations",
    access: RoleGroups.AllOperators,
    title: "Formulário de solicitação",
    submenu: [
      {
        key: "projects",
        access: RoleGroups.AllOperators,
        path: `${APP_PREFIX_PATH}/projects`,
        title: "Projetos",
        breadcrumb: false,
        icon: Icon.LayoutOutlined,
        submenu: [],
      },
      {
        key: "how-did-find",
        access: RoleGroups.AllOperators,
        path: `${APP_PREFIX_PATH}/how-did-find`,
        title: "Como ficou sabendo",
        breadcrumb: false,
        icon: Icon.QuestionCircleOutlined,
        submenu: [],
      },
      {
        key: "situations",
        access: RoleGroups.AllOperators,
        path: `${APP_PREFIX_PATH}/situations`,
        title: "Situações da empresa",
        breadcrumb: false,
        icon: Icon.PercentageOutlined,
        submenu: [],
      },
      {
        key: "reasons",
        access: RoleGroups.AllOperators,
        path: `${APP_PREFIX_PATH}/reasons`,
        title: "Motivos da solicitação",
        breadcrumb: false,
        icon: Icon.InfoCircleOutlined,
        submenu: [],
      },
    ],
  },
];

const configAPPNavTree = [
  {
    key: "configs",
    access: RoleGroups.AllAdmins,
    title: "Configurações",
    submenu: [
      {
        key: "users",
        access: RoleGroups.AllAdmins,
        path: `${APP_PREFIX_PATH}/users`,
        title: "Usuários",
        icon: Icon.TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "logs",
        access: RoleGroups.AllAdmins,
        path: `${APP_PREFIX_PATH}/logsPage`,
        title: "Logs do sistema",
        breadcrumb: false,
        icon: Icon.UnorderedListOutlined,
        submenu: [],
      },
      {
        key: "lgpd",
        access: RoleGroups.AllAdmins,
        path: `${APP_PREFIX_PATH}/lgpd`,
        title: "Excluir dados LGPD",
        icon: Icon.UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...solicitationsNavTree,
  ...operationsNavTree,
  ...formSolicitationsNavTree,
  ...configAPPNavTree,
];

export default navigationConfig;
