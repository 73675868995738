import { useEffect } from 'react';
import Utils from 'utils';
import { useSelector } from 'react-redux';
import { RoleGroups } from 'auth/Roles';

const Validate = (props) => {
  const auth_user = useSelector(({ auth }) => auth);

  const loadInfo = () => {
    if (props.show) {
      console.log(
        'VALIDATION :::: ',
        auth_user.user.role,
        props.id,
        props.type
      );
    }
  };

  useEffect(() => {
    loadInfo();
    //eslint-disable-next-line
  }, []);

  return props.else
    ? Utils.validationPerfil(
      auth_user.user.role,
      RoleGroups.AllPanelUsers,
      props.type,
      props.show
    )
      ? props.content
      : props.else
    : Utils.validationPerfil(
      auth_user.user.role,
      RoleGroups.AllPanelUsers,
      props.type,
      props.show
    ) && props.children;
};

export default Validate;
