import axios from "axios";

import loginService from "./Login.Service";
import defaultService from "./Default.Service";
import uploadService from "./Upload.Service";
import perfilService from "./Perfil.Service";
import usersService from "./Users.Service";
import cepService from "./CEP.Service";
import simulationsService from "./Simulations.Service";
import solicitationsService from "./Solicitations.Service";
import logsService from "./Logs.Service";
import situationsService from "./Situations.Service";
import howService from "./How.Service";
import reasonsService from "./Reasons.Service";
import projectsService from "./Projects.Service";
import solicitationCommentsService from "./SolicitationComments.Service";
import solicitationDocumentsService from "./SolicitationDocuments.Service";
import boaVistaService from "./BoaVista.Service";
import dashboardService from "./Dashboard.Service";
import profileService from "./Profile.Service";
import clientService from "./Clients.Service";
import companyService from "./Company.Service";
import analizeOperationService from "./AnalizeOperation.Service";
import * as Actions from "../redux/constants/Auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
});

export const getAuthToken = () => {
  return localStorage.getItem(Actions.AUTH_TOKEN);
};

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();

    if (token) {
      config.headers["Authorization"] = token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export {
  api,
  loginService,
  defaultService,
  uploadService,
  perfilService,
  usersService,
  cepService,
  simulationsService,
  solicitationsService,
  logsService,
  situationsService,
  howService,
  reasonsService,
  projectsService,
  solicitationCommentsService,
  solicitationDocumentsService,
  boaVistaService,
  dashboardService,
  profileService,
  clientService,
  companyService,
  analizeOperationService,
};
