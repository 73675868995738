import { api } from "./";
import * as Actions from "../redux/constants/Auth";
import { usePaginatedData } from "helpers/ReactQueryPagination";
import { useDebounced } from "hooks/useDebounced";

const service = {};

const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "projects";

service.model = {
  active: false,
  banner: "",
  description: "",
  title: "",
  url: "",
  api_key: "",
};

service.getProjects = async function (params) {
  let result = null;
  // const { page, limit } = params;
  await api
    .get(
      `/api/${endpoint}`,
      {
        params,
      },
      config()
    )
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getProjectsById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.insertProjects = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateProjects = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteProjects = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.uploadDocument = async function (body) {
  let result = null;

  const data = new FormData();
  data.append("file", body.file);
  await api
    .post(`/api/${endpoint}/upload/true`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export function useSearchAllProjects(options = {}) {
  const debouncedSearch = useDebounced(options.search);

  return usePaginatedData(
    ["projects", debouncedSearch],
    {
      url: "/api/v1/projects/search",
      params: {
        query: debouncedSearch,
      },
    },
    options
  );
}

export default service;
