import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import AppLocale from "lang";
import { ConfigProvider } from "antd";
import AppRouter from "routes/AppRouter";
import { PaginationProvider } from "context/pagination";


const Views = (props) => {
  const currentAppLocale = AppLocale[props.locale];
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <PaginationProvider>
          <AppRouter {...props} />
        </PaginationProvider>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(Views);
