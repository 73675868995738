import { ForbiddenComponent } from 'components';
import Loading from 'components/shared-components/Loading';
import React from 'react';
import { useSelector } from 'react-redux';
import { routes, routesConfig } from 'routes';
import { RenderRoutes } from './RenderRoutes';


const loginPagePathname = routes.auth.login();
const homePagePathname = routes.app.home.index();

const AppRouter = (props) => {
    const auth = useSelector(({ auth }) => auth);

    return (
        <RenderRoutes {...props}
            routesConfig={routesConfig}
            auth={auth}
            ForbiddenComponent={ForbiddenComponent}
            SuspenseFallback={<Loading cover="content" />}
            loginPagePathname={loginPagePathname}
            homePagePathname={homePagePathname}
        />
    );
};

export default AppRouter;
