import { RoleGroups } from "auth/Roles";
import { lazy } from "react";
import Content from "./Content";
import { withProviders } from "./withProvider";
import { DataSolicitationProvider } from "context/DataSolicitation";
import { DataClientProvider } from "hooks/useClientInfo";
import AuthLayout from "layouts/auth-layout";
import BoaVistaLayout from "layouts/boavista-layout";
import { DataBoaVistaProvider } from "context/ContextBoaVista";

export const routes = {
  app: {
    home: {
      index: () => "/app/home",
    },
    users: {
      list: () => "/app/users",
      form: (id = ":form") => `/app/users/${id}`,
    },
    profile: {
      index: () => "/app/profile",
    },
    solicitations: {
      list: () => "/app/solicitations",
      status: (status = ":status") => `/app/solicitations/${status}`,
      view: (id = ":id") => `/app/solicitations/view/${id}`,
      viewPending: (id = ":id") => `/app/solicitations/view-pending/${id}`,
    },
    simulations: {
      list: () => "/app/simulations",
    },
    logsPage: {
      list: () => "/app/logsPage",
      view: (id = ":id") => `/app/logsPage/view/${id}`,
    },
    situations: {
      list: () => "/app/situations",
      form: (id = ":form") => `/app/situations/${id}`,
    },
    howDidFind: {
      list: () => "/app/how-did-find",
      form: (id = ":form") => `/app/how-did-find/${id}`,
    },
    reasons: {
      list: () => "/app/reasons",
      form: (id = ":form") => `/app/reasons/${id}`,
    },
    entrepreneurs: {
      list: () => "/app/entrepreneurs",
      form: (id = ":form") => `/app/entrepreneurs/${id}`,
    },
    lgpd: {
      list: () => "/app/lgpd",
      form: (id = ":id") => `/app/lgpd/view/${id}`,
    },
    projects: {
      list: () => "/app/projects",
      form: (id = ":form") => `/app/projects/${id}`,
    },
    companyOperation: {
      company: () => "/app/company-operation",
      remessa: (bankCode = ":bankCode") =>
        `/app/company-operation/banks/${bankCode}/remessa`,
      assignmentRemittance: (bankCode = ":bankCode") =>
        `/app/company-operation/banks/${bankCode}/assignment-remittances`,
      cnabIntegration: (bankId = ":bankId") =>
        `/app/company-operation/cnab-integration/${bankId}`,
      remittance: (bankId = ":bankId") =>
        `/app/company-operation/remittance-operation/${bankId}`,
    },
    allOperations: {
      list: () => "/app/allOperations",
    },
    clientOperation: {
      list: () => "/app/clientOperation",
      view: (id = ":id") => `/app/clientOperation/view/${id}`,
      building: () => "/app/clientOperation/building",
      loanProposal: (id = ":id") => `/app/clientOperation/loan-proposal/${id}`,
      analize: (id = ":id") => `/app/clientOperation/analize/${id}`,
      repurchase: (credit_operation_id = ":credit_operation_id") =>
        `/app/clientOperation/analize/${credit_operation_id}/repurchase`,
      repurchaseTitle: (credit_operation_id = ":credit_operation_id") =>
        `/app/clientOperation/analize/${credit_operation_id}/repurchase/title`,
      repurchaseDetails: (
        credit_operation_id = ":credit_operation_id",
        installmentId = ":installmentId"
      ) =>
        `/app/clientOperation/analize/${credit_operation_id}/repurchase/${installmentId}/details`,
      parcels: (id = ":id", credit_operation_id = ":credit_operation_id") =>
        `/app/clientOperation/parcels/${id}/${credit_operation_id}`,
      payments: (id = ":id", credit_operation_id = ":credit_operation_id") =>
        `/app/clientOperation/payments/${id}/${credit_operation_id}`,
    },
    messages: {
      layout: () => "/app/messages",
    },
  },
  auth: {
    login: () => "/auth/login",
    change: () => "/auth/change",
    reset: () => "/auth/reset",
    confirmEmail: (token = ":token") => `/auth/confirmEmail/${token}`,
    feedbackRecoverPass: () => "/auth/feedbackRecoverPass",
    newPass: () => `/auth/newPass`,
    register: () => "/auth/register",
    twoFactors: () => "/auth/twoFactors",
  },
  boavista: {
    boavista: (id = ":id") => `/boavista/boa-vista/${id}`,
  },
};

export const routesConfig = [
  {
    path: "/app",
    component: lazy(() => import("../layouts/app-layout")),
    roles: RoleGroups.AllAdmins,
    children: [
      {
        path: routes.app.home.index(),
        component: lazy(() => import("../views/app-views/home")),
        roles: RoleGroups.AllPanelUsers,
      },
      {
        path: routes.app.users.list(),
        component: lazy(() => import("../views/app-views/users")),
        children: [
          {
            path: routes.app.users.list(),
            component: lazy(() => import("../views/app-views/users/List")),
          },
          {
            path: routes.app.users.form(),
            component: lazy(() => import("../views/app-views/users/Form")),
          },
        ],
      },
      {
        path: routes.app.profile.index(),
        component: lazy(() => import("../views/app-views/profile")),
      },
      {
        path: routes.app.solicitations.list(),
        component: lazy(() => import("../views/app-views/solicitations")),
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.solicitations.list(),
            component: lazy(() =>
              import("../views/app-views/solicitations/List")
            ),
          },
          {
            path: routes.app.solicitations.status(),
            component: lazy(() =>
              import("../views/app-views/solicitations/ListPending")
            ),
          },
          {
            path: routes.app.solicitations.view(),
            component: lazy(() =>
              import("../views/app-views/solicitations/Form")
            ),
          },
          {
            path: routes.app.solicitations.viewPending(),
            component: lazy(() =>
              import("../views/app-views/solicitations/Form")
            ),
          },
        ],
      },
      {
        path: routes.app.simulations.list(),
        component: lazy(() => import("../views/app-views/simulations")),
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.simulations.list(),
            component: lazy(() =>
              import("../views/app-views/simulations/List")
            ),
          },
        ],
      },
      {
        path: routes.app.logsPage.list(),
        component: lazy(() => import("../views/app-views/logsPage")),
        children: [
          {
            path: routes.app.logsPage.list(),
            component: lazy(() => import("../views/app-views/logsPage/List")),
          },
          {
            path: routes.app.logsPage.view(),
            component: lazy(() => import("../views/app-views/logsPage/Form")),
          },
        ],
      },
      {
        path: routes.app.situations.list(),
        component: lazy(() => import("../views/app-views/company_situation")),
        roles: RoleGroups.AllOperators,
        children: [
          {
            path: routes.app.situations.list(),
            component: lazy(() =>
              import("../views/app-views/company_situation/List")
            ),
          },
          {
            path: routes.app.situations.form(),
            component: lazy(() =>
              import("../views/app-views/company_situation/Form")
            ),
          },
        ],
      },
      {
        path: routes.app.howDidFind.list(),
        component: lazy(() => import("../views/app-views/how_did_find")),
        roles: RoleGroups.AllOperators,
        children: [
          {
            path: routes.app.howDidFind.list(),
            component: lazy(() =>
              import("../views/app-views/how_did_find/List")
            ),
          },
          {
            path: routes.app.howDidFind.form(),
            component: lazy(() =>
              import("../views/app-views/how_did_find/Form")
            ),
          },
        ],
      },
      {
        path: routes.app.reasons.list(),
        component: lazy(() => import("../views/app-views/reasons")),
        roles: RoleGroups.AllOperators,
        children: [
          {
            path: routes.app.reasons.list(),
            component: lazy(() => import("../views/app-views/reasons/List")),
          },
          {
            path: routes.app.reasons.form(),
            component: lazy(() => import("../views/app-views/reasons/Form")),
          },
        ],
      },
      {
        path: routes.app.entrepreneurs.list(),
        component: Content,
        componentProps: { title: "Empreendedores" },
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.entrepreneurs.list(),
            component: lazy(() =>
              import("../views/app-views/entrepreneurs/List")
            ),
          },
          {
            path: routes.app.entrepreneurs.form(),
            component: lazy(() =>
              import("../views/app-views/entrepreneurs/Form")
            ),
          },
        ],
      },
      {
        path: routes.app.lgpd.list(),
        component: Content,
        componentProps: { title: "LGPD" },
        children: [
          {
            path: routes.app.lgpd.list(),
            component: lazy(() => import("../views/app-views/lgpd/List")),
          },
          {
            path: routes.app.lgpd.form(),
            component: lazy(() => import("../views/app-views/lgpd/Form")),
          },
        ],
      },
      {
        path: routes.app.projects.list(),
        component: Content,
        componentProps: { title: "Projetos" },
        roles: RoleGroups.AllOperators,
        children: [
          {
            path: routes.app.projects.list(),
            component: lazy(() => import("../views/app-views/projects/List")),
          },
          {
            path: routes.app.projects.form(),
            component: lazy(() => import("../views/app-views/projects/Form")),
          },
        ],
      },
      {
        path: routes.app.companyOperation.company(),
        component: Content,
        componentProps: { title: "Operações" },
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.companyOperation.company(),
            component: lazy(() =>
              import("../views/app-views/company_operation/View")
            ),
          },
          {
            path: routes.app.companyOperation.remessa(),
            component: lazy(() =>
              import(
                "../views/app-views/company_operation/BankAccounts/components/InvoiceRemittance/InvoiceRemittanceTable"
              )
            ),
          },
          {
            path: routes.app.companyOperation.assignmentRemittance(),
            component: lazy(() =>
              import(
                "../views/app-views/company_operation/BankAccounts/components/AssignmentRemittance/AssignmentRemittanceTable"
              )
            ),
          },
          {
            path: routes.app.companyOperation.cnabIntegration(),
            component: lazy(() =>
              import("../views/app-views/company_operation/CnabIntegration")
            ),
          },
          {
            path: routes.app.companyOperation.remittance(),
            component: lazy(() =>
              import(
                "../views/app-views/company_operation/BankAccounts/components/AssignmentRemittance/RemittanceOperation"
              )
            ),
          },
        ],
      },
      {
        path: routes.app.allOperations.list(),
        component: withProviders([DataSolicitationProvider])(null)(Content),
        componentProps: { title: "Operações" },
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.allOperations.list(),
            component: lazy(() =>
              import("../views/app-views/allOperations/View")
            ),
          },
        ],
      },
      {
        path: routes.app.clientOperation.list(),
        component: lazy(() => import("../views/app-views/clientOperation")),
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.clientOperation.list(),
            component: lazy(() =>
              import("../views/app-views/clientOperation/List")
            ),
          },
          {
            path: routes.app.clientOperation.view(),
            component: lazy(() =>
              import("../views/app-views/clientOperation/Details")
            ),
          },
          {
            path: routes.app.clientOperation.building(),
            component: lazy(() =>
              import("../views/app-views/clientOperation/Building")
            ),
          },
          {
            path: routes.app.clientOperation.loanProposal(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation"
              )
            ),
          },
          {
            path: routes.app.clientOperation.analize(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations"
              )
            ),
          },
          {
            path: routes.app.clientOperation.repurchase(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations/Repurchase/RepurchaseClient"
              )
            ),
          },
          {
            path: routes.app.clientOperation.repurchaseTitle(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations/Repurchase/RepurchaseClient/RepurchaseTitles"
              )
            ),
          },
          {
            path: routes.app.clientOperation.repurchaseDetails(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations/Repurchase/RepurchaseClient/RepurchaseDetails"
              )
            ),
          },
          {
            path: routes.app.clientOperation.parcels(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations/Casualties/paymentsParcels"
              )
            ),
          },
          {
            path: routes.app.clientOperation.payments(),
            component: lazy(() =>
              import(
                "../views/app-views/clientOperation/Details/loanSimulation/AnalizeOperations/Casualties/payments"
              )
            ),
          },
        ],
      },
      {
        path: routes.app.messages.layout(),
        component: withProviders([
          DataSolicitationProvider,
          DataClientProvider,
        ])(null)(Content),
        componentProps: { title: "Disparos de SMS" },
        roles: RoleGroups.AllPanelUsers,
        children: [
          {
            path: routes.app.messages.layout(),
            component: lazy(() => import("../views/app-views/messages/layout")),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: routes.auth.login(),
        component: lazy(() =>
          import("../views/auth-views/authentication/login-2")
        ),
      },
      {
        path: routes.auth.change(),
        component: lazy(() =>
          import("../views/auth-views/authentication/change-password")
        ),
      },
      {
        path: routes.auth.reset(),
        component: lazy(() =>
          import("../views/auth-views/authentication/forgot-password")
        ),
      },
      {
        path: routes.auth.confirmEmail(),
        component: lazy(() =>
          import("../views/auth-views/authentication/confirmEmail")
        ),
      },
      {
        path: routes.auth.feedbackRecoverPass(),
        component: lazy(() =>
          import("../views/auth-views/authentication/recoverPass")
        ),
      },
      {
        path: routes.auth.newPass(),
        component: lazy(() =>
          import("../views/auth-views/authentication/new-pass")
        ),
      },
      {
        path: routes.auth.register(),
        component: lazy(() =>
          import("../views/auth-views/authentication/register-2")
        ),
      },
      {
        path: routes.auth.twoFactors(),
        component: lazy(() =>
          import(`../views/auth-views/authentication/two-factors`)
        ),
      },
    ],
  },
  {
    path: "/boavista",
    component: withProviders([DataBoaVistaProvider])(null)(BoaVistaLayout),
    roles: RoleGroups.AllAdmins,
    children: [
      {
        path: routes.boavista.boavista(),
        component: lazy(() =>
          import("../views/boa-vista/boa_vista/ResultBoaVista")
        ),
      },
    ],
  },
];
