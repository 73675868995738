import React from 'react';
import dateFnsGenerate from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker(dateFnsGenerate);

const Content = (props) =>{
    return <DatePicker { ... props } />;
}
export default Content;



