import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "borrowers";

service.getClients = async function (page, limit, currentSortItem, filterList) {
  let result = null;
  let url = "";
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getAll = async function () {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/all/borrowers`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editClient = async function (id, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getClientById = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createAccountBank = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/bank`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editPersonalInfo = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editAccountBank = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/bank`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editBusiness = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/business`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getShareholders = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/shareholder`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createShareholders = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/shareholder`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });

  return result;
};

service.deleteShareholders = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/shareholder/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.getGuarantor = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/guarantor`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createAttorney = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/attorney`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getAttorney = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/attorney`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteAttorney = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/attorney/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.getDebtor = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/jointdebtor`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createDebtor = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/jointdebtor`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });

  return result;
};

service.deleteDebtor = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/jointdebtor/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.getProducts = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/product`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createProducts = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/product`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });

  return result;
};

service.deleteProducts = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/product/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.getBillings = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/revenue`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createBillings = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/revenue`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });

  return result;
};

service.deleteBillings = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/revenue/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.getPersonalGuarantee = async function (borrowerId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${borrowerId}/securedguarantee`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createPersonalGuarantee = async function (data) {
  const url = `/api/${endpoint}/securedguarantee`;
  let result = null;
  try {
    await api
      .post(url, data, config())
      .then((res) => {
        result = { ...res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = {
          data: null,
          error: true,
          type: error,
          status: error.response.status,
        };
      });

    return result;
  } catch (error) {
    return { data: null, error: true, type: error.message };
  }
};

service.uploadPersonalGuarantee = async function (data) {
  const url = `/api/${endpoint}/securedguarantee/upload`;
  let result = null;
  try {
    await api
      .post(url, data, config())
      .then((res) => {
        result = { ...res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = {
          data: null,
          error: true,
          type: error,
          status: error.response.status,
        };
      });

    return result;
  } catch (error) {
    return { data: null, error: true, type: error.message };
  }
};

service.deletePersonalGuarantee = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/securedguarantee/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
      return result;
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };

      return result;
    });
};

service.createOtherInformations = async function (data) {
  const url = `/api/${endpoint}/otherinformation`;
  let result = null;
  try {
    await api
      .post(url, data, config())
      .then((res) => {
        result = { ...res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = {
          data: null,
          error: true,
          type: error,
          status: error.response.status,
        };
      });

    return result;
  } catch (error) {
    return { data: null, error: true, type: error.message };
  }
};

service.getOtherInformations = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/otherinformation`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editOtherInformations = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/otherinformation`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getParameterization = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/parameters`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getCreditAgentId = async function () {
  let result = null;
  await api
    .get(`/api/users/creditOperators`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editParameterization = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/parameters`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSimulation = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/simulation`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postSimulation = async function (data) {
  const url = `/api/creditOperation/simulation`;
  let result = null;
  try {
    await api
      .post(url, data, config())
      .then((res) => {
        result = { ...res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = {
          data: null,
          error: true,
          type: error,
          status: error.response.status,
        };
      });

    return result;
  } catch (error) {
    return { data: null, error: true, type: error.message };
  }
};

service.getClassification = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/borrowerClassification`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.createBorrower = async function (data) {
  const url = `/api/${endpoint}/create`;
  let result = null;
  try {
    await api
      .post(url, data, config())
      .then((res) => {
        result = { ...res.data, error: false, status: res.status };
      })
      .catch((error) => {
        result = {
          data: null,
          error: true,
          type: error,
          status: error.response.status,
        };
      });

    return result;
  } catch (error) {
    return { data: null, error: true, type: error.message };
  }
};

export default service;
