export const Role = {
    ADMIN: 1,
    USER: 2,
    SUPER_ADMIN: 3,
    AGENT: 4,
    OPERATOR: 5,
};

export const roleNames = {
    [Role.SUPER_ADMIN]: "Super Administrador",
    [Role.ADMIN]: "Administrador",
    [Role.USER]: "Usuário",
    [Role.OPERATOR]: "Operador",
    [Role.AGENT]: "Agente",
};

export const roleAssigningPermissions = {
    [Role.SUPER_ADMIN]: [Role.SUPER_ADMIN, Role.ADMIN, Role.OPERATOR, Role.AGENT],
    [Role.ADMIN]: [Role.ADMIN, Role.OPERATOR, Role.AGENT],
};

export const RoleGroups = {
    OnlySuperAdmin: [Role.SUPER_ADMIN],
    AllAdmins: [Role.SUPER_ADMIN, Role.ADMIN],
    AllOperators: [Role.SUPER_ADMIN, Role.ADMIN, Role.OPERATOR],
    AllPanelUsers: [Role.SUPER_ADMIN, Role.ADMIN, Role.OPERATOR, Role.AGENT],
};