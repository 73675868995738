import React from "react";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Button from "antd/lib/button";
import { FileExcelOutlined } from "@ant-design/icons";
import { format } from "date-fns";

import * as Actions from "redux/actions";
import { useDispatch } from "react-redux";

export default function ExportExcel({
  name_file = "list_exported",
  title_file = "Lista Exportada",
  showExport = false,
  saveLogExport = null,
  isLoading,
}) {
  const dispatch = useDispatch();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const generateFileInfos = async () => {
    dispatch(Actions.openLoading());
    const data = await saveLogExport();
    const ws = XLSX.utils.json_to_sheet(data);
    const wh = XLSX.utils.json_to_sheet([
      {
        Registros: data.length,
        Relatório: title_file,
        Data: format(new Date(), "dd/MM/yyyy hh:mm:ss"),
      },
    ]);
    const wb = {
      Sheets: { Info: wh, Data: ws },
      SheetNames: ["Info", "Data"],
    };

    const excelBuffer = await XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const excelFile = await new Blob([excelBuffer], { type: fileType });
    await FileSaver.saveAs(
      excelFile,
      `${name_file}_${format(new Date(), "dd/MM/yyyy_HH_mm:ss")}` +
        fileExtension
    );
    dispatch(Actions.closeLoading());
  };

  return (
    <>
      {showExport && (
        <Button
          type="default"
          htmlType="button"
          loading={isLoading}
          onClick={generateFileInfos}
          disabled={isLoading}
          icon={<FileExcelOutlined />}
        >
          {" "}
          EXPORTAR EXCEL
        </Button>
      )}
    </>
  );
}
