import React from 'react';
import { Spin } from 'antd';

//import {LoadingOutlined} from '@ant-design/icons';

import { useSelector } from 'react-redux';

const Content = ({ size, children }) => {
  const loadSpin = useSelector(({ system }) => system.loadSpin);
  //const antIcon = <LoadingOutlined style={{fontSize: props.size ? props.size : 24}} spin/>;

  return (
    <Spin spinning={loadSpin} size={size ? size : 'default'}>
      {children}
    </Spin>
  );
};

export default Content;
