import { usePaginatedData } from "helpers/ReactQueryPagination";
import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "company";

service.getCompany = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editDataCompanyInfo = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateRepresentative = async function (data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/representative`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteRepresentative = async function (id) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/representative/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateWitness = async function (data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/witness`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteWitness = async function (id) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/witness/${id}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postWitness = async function (data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/witness`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.postRepresentative = async function (data) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/representative`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getParameterization = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/parameters`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.editParameterization = async function (data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/parameters`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getContracts = async function (id = 1) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contract`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getContractAlias = async function () {
  const url = `/api/${endpoint}/contract/alias`;

  const response = await api.get(url, config());

  return response.data;
};

service.postContract = async function (data, id) {
  const url = `/api/${endpoint}/${id}/contract`;

  await api.post(url, data, config());
};

service.editContract = async function (data, id) {
  const url = `/api/${endpoint}/${id}/contract`;

  await api.put(url, data, config());
};

service.getBankAccounts = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/banks`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postBankAccounts = async function (data, id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/banks`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.editBankAccounts = async function (data, id) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${id}/banks`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteBankAccounts = async function (id) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${id}/banks`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postRemessa = async function (bankCode, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/bank/${bankCode}/remessas`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export function useGetRemessas(options) {
  return usePaginatedData(
    ["remessas", options.bankCode],
    {
      url: `/api/${endpoint}/bank/${options.bankCode}/remessas`,
      params: {
        date: options.date,
      },
    },
    options
  );
}

service.postRemessaReport = async function (id, bankCode, data) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/bank/${bankCode}/remessa/report`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export function useGetAssignmentRemittance(options) {
  return usePaginatedData(
    ["assignment-remittances", options.bankCode],
    {
      url: `/api/${endpoint}/assignment-bank-accounts/${options.bankCode}/assignment-remittances`,
      params: {
        date: options.date,
      },
    },
    options
  );
}

service.getAssignmentRemittance = async function (
  assignmentBankAccountId,
  data
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/assignment-bank-accounts/${assignmentBankAccountId}/assignment-remittances`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postAssignmentRemittance = async function (
  assignmentBankAccountId,
  data
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/assignment-bank-accounts/${assignmentBankAccountId}/assignment-remittances`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: error.response.data,
        error: true,
        status: error.response.status,
      };
    });
  return result;
};

service.getAssignmentBankAccounts = async function (id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/assignment-bank-accounts`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getAssignmentBankAccountById = async function (
  companyId,
  assignmentBankAccountId
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${companyId}/assignment-bank-accounts/${assignmentBankAccountId}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postAssignmentBankAccounts = async function (data, id) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/assignment-bank-accounts`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.putAssignmentBankAccounts = async function (data, id) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${id}/assignment-bank-accounts`;

  await api
    .put(url, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteAssignmentBankAccounts = async function (
  companyId,
  assignmentBankAccountId
) {
  let result = null;
  let url = "";
  url = `/api/${endpoint}/${companyId}/assignment-bank-accounts/${assignmentBankAccountId}`;

  await api
    .delete(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getReportsOperation = async function (params) {
  let url = `/api/${endpoint}/report/operations`;

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/operations${queryString}`;
  }

  try {
    const response = await api.get(url, {
      responseType: "blob",
      headers: {
        Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}`,
      },
    });

    return { data: response.data, error: false, status: response.status };
  } catch (error) {
    return { data: null, error: true, type: error };
  }
};

service.getReportsParametrization = async function (params) {
  let url = `/api/${endpoint}/report/parameterized`;

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/parameterized${queryString}`;
  }
  try {
    const response = await api.get(url, {
      responseType: "blob",
      headers: {
        Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}`,
      },
    });

    return { data: response.data, error: false, status: response.status };
  } catch (error) {
    return { data: null, error: true, type: error };
  }
};

service.getReportsIof = async function (params) {
  let url = `/api/${endpoint}/report/iof`;

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/iof${queryString}`;
  }

  try {
    const response = await api.get(url, {
      responseType: "blob",
      headers: {
        Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}`,
      },
    });

    return { data: response.data, error: false, status: response.status };
  } catch (error) {
    return { data: null, error: true, type: error };
  }
};

service.getIofPDF = async function (params) {
  let url = `/api/${endpoint}/report/pdf/iof`;

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/pdf/iof${queryString}`;
  }
  let result = null;
  try {
    await api.get(url, config()).then((res) => {
      result = { data: res.data, error: false, status: res.status };
    });
  } catch (error) {
    result = { data: null, error: true, type: error };
  }

  return result;
};
service.getContractsList = async function (id, page, limit) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contracts`;

  const params = {
    page,
    limit,
  };
  await api
    .get(
      url,
      {
        params,
      },
      config()
    )
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getCreditOperationContracts = async function (
  companyId,
  creditOperationId
) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${companyId}/contracts/credit-operation/${creditOperationId}`;
  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.cancelContract = async function (id, contractId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contracts/${contractId}`;

  await api
    .patch(url, {}, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getContractsSign = async function (id, contractId) {
  let result = null;
  let url = "";

  url = `/api/${endpoint}/${id}/contracts/${contractId}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getReportsExcelCession = async function (params) {
  let url = `/api/${endpoint}/report/operation/remittance/excel`;

  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/operation/remittance/excel${queryString}`;
  }

  try {
    const response = await api.get(url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(Actions.AUTH_TOKEN)}`,
      },
    });

    return { data: response.data, error: false, status: response.status };
  } catch (error) {
    return {
      data: null,
      error: true,
      type: error,
      status: error.response ? error.response.status : 500,
    };
  }
};

service.getReportsPdfCession = async function (params) {
  let url = `/api/${endpoint}/report/operation/remittance/pdf`;

  let result = null;
  if (params) {
    const filterParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
      )
      .map((key) => `filter[${key}]=${params[key]}`)
      .join("&");

    const queryString = filterParams ? `?${filterParams}` : "";

    url = `/api/${endpoint}/report/operation/remittance/pdf${queryString}`;
  }

  try {
    const response = await api.get(url, config());
    result = { data: response.data, error: false, status: response.status };
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      type: error,
      status: error.response ? error.response.status : 500,
    };
  }
};

export default service;
