import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "solicitations";

service.getAllSolicitations = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getAllSolicitationsDoc = async function (
  page,
  limit,
  currentSortItem,
  filterList
) {
  let result = null;
  let url = "";
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;

      if (filterList[key] === 1 || filterList[key] === 2)
        filterParams += `&filter[${key}]=${
          filterList[key] === 1 ? true : false
        }`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/all-ser/doc?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/all-ser/doc?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(`${url}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSolicitationById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSolicitationUserById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/user/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getPendingSolicitations = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}/review`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getPendingDocSolicitations = async function (
  page,
  limit,
  currentSortItem,
  filterList
) {
  let result = null;
  let url = "";
  let filterParams = "";

  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;

      if (filterList[key] === 1 || filterList[key] === 2)
        filterParams += `&filter[${key}]=${
          filterList[key] === 1 ? true : false
        }`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/review-doc?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/review-doc?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getCompletedSolicitations = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}/complete`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteSolicitation = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.exportSolicitations = async function (filters) {
  let result = null;
  await api
    .post(`/api/${endpoint}/export`, filters, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.putDocumentApproved = async function (data) {
  let result = null;
  await api
    .put(`/api/documents/approve`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.putSolicitationStatusOnly = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}/status_only`, data, config())
    .then((res) => {
      result = { data: res.data.status, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.putSolicitationProjectOnly = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}/project_only`, data, config())
    .then((res) => {
      result = { data: res.data.status, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.approveSolicitation = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}/status`, data, config())
    .then((res) => {
      result = { data: res.data.status, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateDocument = async function (body, id) {
  let result = null;
  const data = new FormData();

  const { file, type, solicitation_id, guarantor_id } = body;

  data.append("file", file);
  data.append("type", type);
  solicitation_id && data.append("solicitation_id", solicitation_id);
  guarantor_id && data.append("guarantor_id", guarantor_id);

  console.log(data, "guarantor", guarantor_id);
  await api
    .put(`/api/documents/update-document/${id}`, data, config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("uploadDocument error :::", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.uploadDocument = async function ({ file, type, solicitation_id }) {
  let result = null;
  const data = new FormData();
  data.append("file", file);
  data.append("type", type);
  data.append("solicitation_id", solicitation_id);

  await api
    .post(`/api/documents/upload/`, data)
    .then((res) => {
      console.log("RES DOC ==> ", res);
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("RES DOC ERROR ==> ");
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getSignedUrl = async function ({ fileName }) {
  let result = null;
  await api
    .get(`/api/v1/generate-signed-url/${fileName}`, config())
    .then((res) => {
      console.log("RES DOC ==> ", res);
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("RES DOC ERROR ==> ");
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getSolicitationsByStatus = async function (solicitation_id, status) {
  let result = null;
  await api
    .get(
      `/api/v1/${endpoint}/cancelled-approved/${solicitation_id}/${status.toUpperCase()}`,
      config()
    )
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

export default service;
