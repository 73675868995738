import { api } from "./";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "company-situations";

service.model = {
  updated_at: "",
  active: false,
  label: "",
  percentage: 0,
};

service.getSituations = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSituationsById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.insertSituations = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.updateSituations = async function (data) {
  let result = null;
  await api
    .put(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteSituations = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

export default service;
