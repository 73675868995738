import { api } from ".";
import * as Actions from "../redux/constants/Auth";

/**
 * @typedef {Object} Result
 * @property {*} data - The data associated with the result.
 * @property {boolean} error - Indicates whether an error occurred.
 * @property {import('axios').AxiosError} type - The type of the result.
 * @property {*} status - The status of the result.
 */

const service = {};

const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "solicitation-documents";

service.model = {
  username: "",
  title: "",
  description: "",
  createdAt: "",
};

service.getSolicitationDocuments = async function (solicitationId, params) {
  /**
   * @type {Result}
   */
  let result = null;
  // const { page, limit } = params;
  await api
    .get(
      `/api/${endpoint}/${solicitationId}`,
      {
        params,
      },
      config()
    )
    .then((res) => {
      console.log("getSolicitationDocuments res :", res);
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSolicitationDocumentsById = async function (id) {
  /**
   * @type {Result}
   */
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.insertSolicitationDocuments = async function ({
  description,
  solicitationId,
  documentFile,
}) {
  /**
   * @type {Result}
   */
  let result = null;

  const data = new FormData();
  data.append("file", documentFile);
  data.append("description", description);
  data.append("solicitationId", solicitationId);

  await api
    .post(`/api/${endpoint}/upload`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteSolicitationDocument = async function (
  solicitationId,
  documentId
) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${solicitationId}/${documentId}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

export default service;
