// import axios from 'axios';
// import * as Actions from "../redux/constants/Auth";

const contentService = {}

contentService.model = {
		titulo: '',
		ativo: 0,
		texto: ''
}

export default contentService
