import * as Actions from '../constants/Auth';
import * as Services from "../../services";
import Utils from "../../utils";

export const refreshToken = async () => {
  const result = await Services.loginService.refreshToken();
  if (!result.error) {
    return result.data;
  } else {
    Utils.feedback('error', 'Alerta', 'Não foi possível carregar o conteúdo, entre em contato com a administração.');
    return [];
  }
}

export const signIn = (user) => {
  return {
    type: Actions.SIGNIN,
    payload: user
  }
};

export const authenticated = (data) => {
  return {
    type: Actions.AUTHENTICATED,
    payload: data
  }
};

export const signOut = () => {
  return {
    type: Actions.SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: Actions.SIGNOUT_SUCCESS,
  }
};

export const signUp = (user) => {
  return {
    type: Actions.SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: Actions.SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: Actions.SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: Actions.SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithFacebook = () => {
  return {
    type: Actions.SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: Actions.SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = (message) => {
  return {
    type: Actions.SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: Actions.HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = (value = true) => {
  return {
    type: Actions.SHOW_LOADING,
    payload: value
  };
};

export const updateUser = (user) => {
  return {
    type: Actions.UPDATE_USER,
    payload: user
  }
};
