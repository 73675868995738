import React from "react";
import { PageHeader } from "components";

const Content = ({ children, title }) => {
    console.log(children)
    return (
        <>
            <PageHeader title={title} display={true} />
            {children}
        </>
    );
};

export default Content;
