import * as Actions from '../constants/Auth';


const initState = {
		loading: false,
		message: '',
		showMessage: false,
		redirect: '',
		token: localStorage.getItem(Actions.AUTH_TOKEN),
		user: localStorage.getItem(Actions.AUTH_USER) ? JSON.parse(localStorage.getItem(Actions.AUTH_USER)) : null,
		force_senha:''
}

const auth = (state = initState, action) => {
		switch (action.type) {
				case Actions.AUTHENTICATED:
						localStorage.setItem(Actions.AUTH_TOKEN, action.payload.token);
						localStorage.setItem(Actions.AUTH_USER, JSON.stringify(action.payload));
						return {
								...state,
								loading: false,
								redirect: '/',
								token: action.payload.token,
								user: action.payload,
								force_senha:action.payload.force_senha
						}
				case Actions.SHOW_AUTH_MESSAGE:
						return {
								...state,
								message: action.message,
								showMessage: true,
								loading: false
						}
				case Actions.HIDE_AUTH_MESSAGE:
						return {
								...state,
								message: '',
								showMessage: false,
						}
				case Actions.SIGNOUT_SUCCESS: {
						localStorage.removeItem(Actions.AUTH_TOKEN);
						localStorage.removeItem(Actions.AUTH_USER);
						return {
								...state,
								token: null,
								user: null,
								redirect: '/',
								loading: false
						}
				}
				case Actions.SIGNUP_SUCCESS: {
						return {
								...state,
								loading: false,
								token: action.token
						}
				}
				case Actions.SHOW_LOADING: {
						return {
								...state,
								loading: action.payload
						}
				}
				case Actions.UPDATE_USER: {
						const data = {
								...state.user,
								...action.payload
						}
						localStorage.removeItem(Actions.AUTH_USER);
						localStorage.setItem(Actions.AUTH_USER, JSON.stringify(data));
						return {
								...state,
								user: data
						}
				}
				default:
						return state;
		}
}

export default auth
