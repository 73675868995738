import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "logs";

service.getAllLogs = async function (page, limit, currentSortItem, filterList) {
  let result = null;
  let url = "";

  let filterParams = "";
  if (filterList) {
    for (const key in filterList) {
      if (filterList[key].length > 0)
        filterParams += `&filter[${key}]=${filterList[key]}`;
    }
  }

  if (currentSortItem.field) {
    url = `/api/${endpoint}/all?page=${page}&limit=${limit}${filterParams}&sort[${currentSortItem.field}]=${currentSortItem.value}`;
  } else {
    url = `/api/${endpoint}/all?page=${page}&limit=${limit}${filterParams}`;
  }

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response ? error.response.status : null,
      };
    });

  console.log(result);
  return result;
};

service.getLogsById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.exportLogs = async function (filters) {
  let result = null;
  await api
    .post(`/api/${endpoint}/export`, filters, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

export default service;
