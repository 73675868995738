import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const service = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};

const endpoint = "boavista";

service.model = {
  active: false,
  label: "",
};

service.getBoaVista = async function () {
  let result = null;
  await api
    .get(`/api/${endpoint}`, config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getSolicitationBoaVista = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/idSolicitation/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getReportBoaVista = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/report/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.getAllBoaVistaById = async function (id) {
  let result = null;
  await api
    .get(`/api/${endpoint}/all/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postReportBoaVista = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}/new-report`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

service.postBoaVista = async function (data) {
  let result = null;
  await api
    .post(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export default service;
