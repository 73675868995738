import React, { createContext, useState } from "react";

const DataBoaVistaContext = createContext();

function DataBoaVistaProvider({ children }) {
  const [respDataBoaVista, setRespDataBoaVista] = useState("");

  const setRespBoaVistaData = async (data) => {
    setRespDataBoaVista(data);
  };

  return (
    <DataBoaVistaContext.Provider
      value={{
        respDataBoaVista,
        setRespBoaVistaData,
      }}
    >
      {children}
    </DataBoaVistaContext.Provider>
  );
}

export { DataBoaVistaProvider, DataBoaVistaContext };
