import * as Services from 'services';
import {message} from "antd";

export const listAllUsers = async () => {
		const result = await Services.usersService.getUsers();
		if (!result.error) {
				return result.data;
		} else {
				message.error({content: `Houve uma falha no carregamento das informações.`, duration: 2});
				return [];
		}
}
