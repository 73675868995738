import React, { useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import * as Actions from 'redux/actions';
import { useDispatch } from 'react-redux';
import Utils from '../../../utils';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const PictureCard = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    fileList: [],
  });

  useEffect(() => {
    setState({ ...state, fileList: props.data });
  }, [state, props.data]);

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = async ({ fileList }) => {
    let listFiles = [];
    let _error = false;

    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj);
    formData.append('nome', 'teste');
    dispatch(Actions.openLoading());
    const result = await Actions.uploadImage(formData);
    if (result === 'false') {
      _error = true;
    }
    listFiles.push({
      uid: fileList[0].uid,
      name: fileList[0].name,
      status: 'done',
      url: result,
    });
    if (!_error) {
      Utils.feedback('error', 'Alerta', 'Houve uma falha, tente novamente!');
    } else {
      Utils.feedback('success', 'SUCESSO', 'Imagens salvas com sucesso!');
    }
    dispatch(Actions.closeLoading());
    setState({ ...state, fileList: [...state.fileList, ...fileList] });
  };

  const { previewVisible, previewImage } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='ant-upload-text'>Upload</div>
    </div>
  );

  const handle = (item) => {
    console.log('item', item);
  };

  return (
    <div className='clearfix'>
      <Upload
        action='../../api/upload'
        listType='picture-card'
        fileList={state.fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handle}
        multiple={props.multi}
      >
        {uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default PictureCard;
