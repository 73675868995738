import { api } from "./";
import * as Actions from "../redux/constants/Auth";

/**
 * @typedef {Object} Result
 * @property {*} data - The data associated with the result.
 * @property {boolean} error - Indicates whether an error occurred.
 * @property {import('axios').AxiosError} type - The type of the result.
 * @property {*} status - The status of the result.
 */

const service = {};

const CONFIG = {
  headers: {
    Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}`,
  },
};

const endpoint = "solicitation-comments";

service.model = {
  username: "",
  comment: "",
  createdAt: "",
};

service.getSolicitationComments = async function (solicitationId, params) {
  /**
   * @type {Result}
   */
  let result = null;
  // const { page, limit } = params;
  await api
    .get(
      `/api/${endpoint}/${solicitationId}`,
      {
        params,
      },
      {
        headers: {
          Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}`,
        },
      }
    )
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.getSolicitationCommentsById = async function (id) {
  /**
   * @type {Result}
   */
  let result = null;
  await api
    .get(`/api/${endpoint}/${id}`, CONFIG)
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.insertSolicitationComments = async function (data) {
  /**
   * @type {Result}
   */
  let result = null;

  await api
    .post(`/api/${endpoint}`, data, CONFIG)
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

service.deleteSolicitationComments = async function (id) {
  let result = null;
  await api
    .delete(`/api/${endpoint}/${id}`, CONFIG)
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

export default service;
