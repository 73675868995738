import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "constants/ThemeConstant";

export const APP_NAME = "Banco Pérola";
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const BOAVISTA_PREFIX_PATH = "/boavista";
export const PROJECT_PREFIX_PATH = "/project";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "pt",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#ffffff",
  headerNavColor: "#ffffff",
  mobileNav: false,
};
