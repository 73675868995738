import React from 'react'

export const BoaVistaLayout = ({ children }) => {
	return (
		<div className="boavista-container">
			{children}
		</div>
	)
}


export default BoaVistaLayout
