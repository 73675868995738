import React from "react";
import AppBreadcrumb from "./AppBreadcrumb";

export const PageHeader = ({ title = "Home", display, concat = "" }) => {
  return display ? (
    <div className="app-page-header">
      <h3 className="mb-0 mr-3 font-weight-semibold">
        {concat ? title + " > " + concat : title}
      </h3>
      {/* <AppBreadcrumb /> */}
    </div>
  ) : null;
};

export default PageHeader;
