import * as Actions from '../constants/System.constants';

export const setBanner = (value) => {
	return {
			type: Actions.SET_BANNER,
			payload: {value,tipo:1}
	};
};

export const openLoading = () => {
		return {
				type: Actions.SYSTEM_SHOW_LOADING,
				payload: true
		};
};

export const closeLoading = () => {
		return {
				type: Actions.SYSTEM_HIDE_LOADING,
				payload: false
		};
};

export const autoMessage = (show, message) => {
		return {
				type: Actions.SYSTEM_MESSAGE,
				show,
				message
		}
}

export const loading = (value) => {
		return {
				type: Actions.SYSTEM_LOADING,
				value
		}
}

export const setFiles = (data) => {
		return {
				type: Actions.LIST_FILES,
				payload: data
		}
}

export const addFiles = (data) => {
		return {
				type: Actions.ADD_FILES,
				payload: data
		}
}

export const setPage = (data) => {
		return {
				type: Actions.SET_PAGE,
				payload: data
		}
}
