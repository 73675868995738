import { api } from "./";
import * as Actions from "../redux/constants/Auth";

const contentService = {};
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  };
};
let result = null;

contentService.model = {
  titulo: "",
  ativo: 0,
};

contentService.getList = async function (endpoint) {
  await api
    .get(`/api/${endpoint}/list`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.getListActives = async function (endpoint) {
  await api
    .get(`/api/${endpoint}/list-actives`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.insertContent = async function (endpoint, data) {
  await api
    .post(`/api/${endpoint}/`, data, config())
    .then((res) => {
      if (res.status === 200) {
        result = { ...res.data, error: false, type: res.status };
      } else {
        result = { ...res.data, error: true };
      }
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.insertContentAllModal = async function (endpoint, data) {
  await api
    .post(`/api/${endpoint}`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.getContent = async function (endpoint, id) {
  await api
    .get(`/api/${endpoint}/id/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.getContentModal = async function (endpoint, id) {
  await api
    .get(`/api/${endpoint}/id/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.getFiles = async function (type, id) {
  await api
    .get(`/api/upload/id/${id}/${type}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.removeFiles = async function (id) {
  await api
    .delete(`/api/upload/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.updateContent = async function (endpoint, data) {
  await api
    .put(`/api/${endpoint}/`, data, config())
    .then((res) => {
      if (res.status === 200) {
        result = { ...res.data, error: false, type: res.status };
      } else {
        result = { ...res.data, error: true };
      }
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.updateStatus = async function (endpoint, data) {
  await api
    .put(`/api/${endpoint}/status`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.deleteContent = async function (endpoint, id) {
  await api
    .delete(`/api/${endpoint}/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.changePassword = async function (endpoint, data) {
  await api
    .put(`/api/${endpoint}/new-password`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.resetPassword = async function (endpoint, data) {
  await api
    .post(`/api/${endpoint}/reset-password`, data, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

//INTERESTS

contentService.listPerfilByInterests = async function (id, type) {
  await api
    .get(`/api/interests/list-perfil/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

contentService.listOptionsByInterests = async function (id, type) {
  await api
    .get(`/api/interests/list-options/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, type: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

export default contentService;
