import React, { createContext, useContext, useState } from "react";
import { clientService } from "services";
import Utils from "utils";

const DataClientContext = createContext();

function DataClientProvider({ children }) {
  const [personalData, setPersonalData] = useState({});
  const [bankData, setBankData] = useState({});
  const [businessData, setBusinessData] = useState({});

  const getClientById = async (id) => {
    try {
      const result = await clientService.getClientById(id);

      const birthday = Utils.formattedDate(result.personalData?.birthday);

      const aux = {
        id: result.id,
        ...result.personalData,
      };

      aux.birthday = birthday;

      setPersonalData(aux);
      setBankData([...result.bankData]);

      setBusinessData(result.businessData || null);

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const editClient = async (data) => {
    try {
      const result = await clientService.editPersonalInfo(data);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const editBusiness = async (data) => {
    try {
      const result = await clientService.editBusiness(data);
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DataClientContext.Provider
      value={{
        personalData,
        bankData,
        businessData,
        getClientById,
        editClient,
        editBusiness,
      }}
    >
      {children}
    </DataClientContext.Provider>
  );
}

function useDataClientInfo() {
  const context = useContext(DataClientContext);
  return context;
}

export { DataClientProvider, useDataClientInfo };
