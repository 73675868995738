import React from 'react';
import * as Icons from '@ant-design/icons';
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { routes } from 'routes';

const ForbiddenComponent = ({ fallbackPath = routes.app.home }) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(fallbackPath);
	}

	return <div className='row mt-5 align-items-center  align-items-center justify-content-center'>
		<div className='col-4'>
			<div className='row col-12 justify-content-between'>
				<div className='text-danger'><Icons.FrownOutlined style={{ fontSize: '5rem' }} /></div>
				<div className='ml-3'>
					<div className="font-weight-bold font-size-md text-danger">
						Desculpe,
						<br /> você não tem permissão
						<br />de acesso a este conteúdo!
					</div>
				</div>
			</div>
			<div className='col-12 mt-3'>
				<Button type="primary" danger shape="round" icon={<Icons.LeftOutlined />} size='middle' onClick={handleClick} />
			</div>
		</div>

	</div>
}

export default ForbiddenComponent;
