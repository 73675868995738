import React, { useState, createContext, useContext } from "react";

const PaginationContext = createContext({
  handlePaginationChange: (page = 1, pageSize = 10) => {},
  handleSetTotalItems: (totalItems) => {},
  handleSortChange: (name = "", order = "") => {},
  currentPage: 0,
  pageLimit: 0,
  totalItems: 0,
  currentSortItem: {},
});

export const handleSortOrderFormat = (order) => {
  switch (order) {
    case "ascend":
      return "ASC";
    case "descend":
      return "DESC";
    default:
      return "DESC";
  }
};

export const PaginationProvider = ({ children }) => {
  //table pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSortItem, setCurrentSortItem] = useState({});

  const handlePaginationChange = (page = 1, pageSize = 10) => {
    setCurrentPage(page);
    setPageLimit(pageSize);
  };

  const handleSetTotalItems = (totalItems) => {
    setTotalItems(totalItems);
  };

  const handleSortChange = (sorter) => {
    if (!sorter.field || !sorter.order) {
      setCurrentSortItem({});
      return;
    }

    setCurrentSortItem({
      field: sorter.field,
      value: handleSortOrderFormat(sorter.order),
    });
  };

  return (
    <PaginationContext.Provider
      value={{
        handlePaginationChange,
        handleSetTotalItems,
        handleSortChange,
        currentPage,
        pageLimit,
        totalItems,
        currentSortItem,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePaginationContext = () => useContext(PaginationContext);
