import React, { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

import * as Actions from 'redux/actions';
import { useDispatch } from 'react-redux';

import './style.css';

const { Dragger } = Upload;

export const Drag = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
  });

  useEffect(() => {
    setState({ ...state, fileList: props.data });
  }, [state, props.data]);

  const config = {
    name: 'file',
    multiple: props.multi,
    action: `http://localhost:21038/api/upload/all/${props.type}/${props.id}`,
    onChange(info) {
      const { status, response } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        dispatch(Actions.addFiles({ arquivo: response.url, id: response.id }));
        message.success(`${info.file.name}: arquivo enviado com sucesso!`);
      } else if (status === 'error') {
        message.error(`${info.file.name}: houve um erro no envio!`);
      }
    },
  };

  return (
    <div className='clearfix'>
      <Dragger data={props.data} listType='picture' {...config}>
        <p className='ant-upload-drag-icon'>
          <CloudUploadOutlined />
        </p>
        <p className='ant-upload-text'>
          Clique ou arraste os arquivos para esta área!
        </p>
      </Dragger>
    </div>
  );
};

export default Drag;
