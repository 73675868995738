export const SYSTEM_SHOW_LOADING = '[SYSTEM] SHOW_LOADING'
export const SYSTEM_HIDE_LOADING = '[SYSTEM] HIDE_LOADING'
export const SYSTEM_MESSAGE = '[SYSTEM] MESSAGE'
export const SYSTEM_LOADING = '[SYSTEM] LOADING'
export const LIST_FILES = "[SYSTEM] LIST OF FILES";
export const ADD_FILES = "[SYSTEM] ADD NEW FILE TO A LIST";
export const SET_PAGE = "[SYSTEM] SET PAGE";
export const SET_BANNER = "[SYSTEM] SET_BANNER";


