import React, { createContext, useState } from "react";
import Utils from "utils";

const DataSolicitationContext = createContext();

function DataSolicitationProvider({ children }) {
  const [infoSolicitation, setInfoSolicitation] = useState({});
  const [dataSolicitation, setDataSolicitation] = useState({
    id: null,
    user_id: null,
    typeCredit: null,
    cnpj: null,
    legalName: null,
    birthday: null,
    genre: null,
    relations: null,
    email: null,
    phone: null,
    cep: null,
    address: null,
    addressNumber: null,
    addressComplement: null,
    neighborhood: null,
    financingValue: null,
    city: null,
    state: null,
    companyType: null,
    nationality: null,
    instagram: null,
    facebook: null,
    site: null,
    profileAccountBank: null,
    cpfCnpj: null,
    nameCreditCard: null,
    bankName: null,
    bankAgency: null,
    bankNumber: null,
    bankDigit: null,
    typeAccount: null,
    keyPix: null,
    certiMEI: null,
    extratoBancario: null,
    fotoFachada: null,
    fotoInterior: null,
    compEndereco: null,
    docFrente: null,
    docVerso: null,
    fotoRgCnh: null,
    docSelfie: null,
    comprResid: null,
    extraBancarioEmpresa: null,
    partners: null,
    lendingPlotsToPay: null,
    lendingValue: null,
    howDidFind: null,
    companyName: null,
    companyCep: null,
    companyAddress: null,
    companyAddressNumber: null,
    companyAddressNeighborhood: null,
    companyCity: null,
    companyState: null,
    companyAddressComplement: null,
    docRNM: null,
    docRefugeProtocol: null,
    typeDocument: null,
    numDocumentForeign: null,
    cpf: null,
    lendingReason: null,
  });

  //respOld - utilizado para as informacoes das solicitacoes preenchidas na versao anterior do sistema
  const [questionPJ, setQuestionPJ] = useState({
    data: [
      {
        title: "Dados financeiros",
        idStep: 1,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Valor do crédito",
            resp: "lendingValue",
            respOld: "",
          },
          {
            question: "Total de parcelas",
            resp: "lendingPlotsToPay",
            respOld: "",
          },
          {
            question: "Tipo de crédito",
            resp: "typeCredit",
            respOld: "",
          },
          {
            question: "Ramo de atividade",
            resp: "branchOfActivity",
            respOld: "",
          },
        ],
      },
      {
        title: "Dados pessoais",
        idStep: 3,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Nome completo",
            resp: "name",
            respOld: "",
          },
          {
            question: "CPF",
            resp: "cpf",
            respOld: "",
          },
          {
            question: "Data de nascimento",
            resp: "birthday",
            respOld: "",
          },
          {
            question: "Gênero",
            resp: "genre",
            respOld: "",
          },
          {
            question: "Estado civil",
            resp: "relations",
            respOld: "",
          },
          {
            question: "E-mail para contato",
            resp: "email",
            respOld: "",
          },
          {
            question: "Telefone para contato",
            resp: "phone",
            respOld: "",
          },
          {
            question: "Profissão",
            resp: "profession",
            respOld: "",
          },
          {
            question: "CEP",
            resp: "cep",
            respOld: "",
          },
          {
            question: "Endereço residencial",
            resp: "address",
            respOld: "",
          },
          {
            question: "Número",
            resp: "addressNumber",
            respOld: "",
          },
          {
            question: "Complemento",
            resp: "addressComplement",
            respOld: "",
          },
          {
            question: "Bairro",
            resp: "neighborhood",
            respOld: "",
          },
          {
            question: "Cidade",
            resp: "city",
            respOld: "",
          },
          {
            question: "Estado (UF)",
            resp: "state",
            respOld: "",
          },
          {
            question: "Nacionalidade",
            resp: "nationality",
            respOld: "",
          },
        ],
      },
      {
        title: "Dados da empresa",
        idStep: 4,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "CNPJ",
            resp: "cnpj",
            respOld: "",
          },
          {
            question: "Nome fantasia da sua empresa",
            resp: "companyName",
            respOld: "",
          },
          {
            question: "CEP",
            resp: "companyCep",
            respOld: "",
          },
          {
            question: "Endereço comercial",
            resp: "companyAddress",
            respOld: "",
          },
          {
            question: "Número",
            resp: "companyAddressNumber",
            respOld: "",
          },
          {
            question: "Complemento",
            resp: "companyAddressComplement",
            respOld: "",
          },
          {
            question: "Bairro",
            resp: "companyAddressNeighborhood",
            respOld: "",
          },
          {
            question: "Cidade",
            resp: "companyCity",
            respOld: "",
          },
          {
            question: "Estado (UF)",
            resp: "companyState",
            respOld: "",
          },
          {
            question: "Tipo da empresa",
            resp: "companyType",
            respOld: "",
          },
          {
            question: "Site",
            resp: "site",
            respOld: "",
          },
          {
            question: "Instagram",
            resp: "instagram",
            respOld: "",
          },
          {
            question: "Facebook",
            resp: "facebook",
            respOld: "",
          },
        ],
      },
      {
        title: "Dados bancários",
        idStep: 5,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Perfil da conta",
            resp: "profileAccountBank",
            respOld: "",
          },
          {
            question: "CPF ou CNPJ da conta",
            resp: "cpfCnpj",
            respOld: "",
          },
          {
            question: "Nome do favorecido",
            resp: "nameCreditCard",
            respOld: "",
          },
          {
            question: "Nome do seu banco",
            resp: "bankName",
            respOld: "",
          },
          {
            question: "Número da sua agência sem o dígito",
            resp: "bankAgency",
            respOld: "",
          },
          {
            question: "Número da conta sem dígito",
            resp: "bankNumber",
            respOld: "",
          },
          {
            question: "Dígito da conta",
            resp: "bankDigit",
            respOld: "",
          },
          {
            question: "Tipo de conta",
            resp: "typeAccount",
            respOld: "",
          },
          {
            question: "Chave PIX",
            resp: "keyPix",
            respOld: "",
          },
        ],
      },
      {
        title: "Documentos pessoais",
        idStep: 6,
        type: "doc",
        foreign: "brasileiro",
        questions: [
          {
            question: "RG ou CNH (frente)",
            resp: "docFrente",
            id: 6,
          },
          {
            question: "RG ou CNH (verso)",
            resp: "docVerso",
            id: 7,
          },
          {
            question: "CPF",
            resp: "fotoRgCnh",
            id: 5,
          },
          {
            question: "Foto selfie (sua) segurando um documento com foto",
            resp: "docSelfie",
            id: 12,
          },
          {
            question: "Comprovante de residência",
            resp: "comprResid",
            id: 8,
          },
          {
            question: "Foto do cabeçalho do extrato bancário",
            resp: "extratoBancario",
            id: 9,
          },
        ],
      },
      {
        title: "Documentos pessoais",
        idStep: 6,
        type: "doc",
        foreign: "estrangeiro",
        questions: [
          {
            question: "Tipo de documento de identificação",
            resp: "typeDocument",
            id: null,
          },
          {
            question: "Número do documento",
            resp: "numDocumentForeign",
            id: null,
          },
          {
            question: "RNM ou Protocolo",
            resp: "docRNM",
            id: 15,
          },
          {
            question: "Comprovante de residência",
            resp: "comprResid",
            id: 8,
          },
          {
            question: "Extrato bancário",
            resp: "extratoBancario",
            id: 9,
          },
          {
            question: "Foto selfie",
            resp: "docSelfie",
            id: 12,
          },
          {
            question: "Foto do seu negócio ou atividade produtiva",
            resp: "docRefugeProtocol",
            id: 14,
          },
        ],
      },
      {
        title: "Documentos da empresa",
        idStep: 7,
        type: "doc",
        foreign: null,
        questions: [
          {
            question: "Contrato social ou certificado MEI",
            resp: "certiMEI",
            id: 1,
          },
          {
            question: "Extrato bancário dos últimos 3 meses",
            resp: "extraBancarioEmpresa",
            id: 10,
          },
          {
            question: "Foto: fachada do empreendimento",
            resp: "fotoFachada",
            id: 3,
          },
          {
            question: "Foto: interior do empreendimento",
            resp: "fotoInterior",
            id: 4,
          },
          {
            question: "Comprovante de endereço da sua empresa",
            resp: "compEndereco",
            id: 11,
          },
        ],
      },
    ],
  });

  const [questionPF, setQuestionPF] = useState({
    data: [
      {
        title: "Dados financeiros",
        idStep: 1,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Valor do crédito",
            resp: "lendingValue",
            respOld: "",
          },
          {
            question: "Total de parcelas",
            resp: "lendingPlotsToPay",
            respOld: "",
          },
          {
            question: "Tipo de crédito",
            resp: "typeCredit",
            respOld: "",
          },
          {
            question: "Ramo de atividade",
            resp: "branchOfActivity",
            respOld: "",
          },
        ],
      },
      {
        title: "Dados pessoais",
        idStep: 2,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Nome completo",
            resp: "name",
            respOld: "",
          },
          {
            question: "CPF",
            resp: "cpf",
            respOld: "",
          },
          {
            question: "Data de nascimento",
            resp: "birthday",
            respOld: "",
          },
          {
            question: "Gênero",
            resp: "genre",
            respOld: "",
          },
          {
            question: "Estado civil",
            resp: "relations",
            respOld: "",
          },
          {
            question: "E-mail para contato",
            resp: "email",
            respOld: "",
          },
          {
            question: "Telefone para contato",
            resp: "phone",
            respOld: "",
          },
          {
            question: "Profissão",
            resp: "profession",
            respOld: "",
          },
          {
            question: "CEP",
            resp: "cep",
            respOld: "",
          },
          {
            question: "Endereço residencial",
            resp: "address",
            respOld: "",
          },
          {
            question: "Número",
            resp: "addressNumber",
            respOld: "",
          },
          {
            question: "Complemento",
            resp: "addressComplement",
            respOld: "",
          },
          {
            question: "Bairro",
            resp: "neighborhood",
            respOld: "",
          },
          {
            question: "Cidade",
            resp: "city",
            respOld: "",
          },
          {
            question: "Estado (UF)",
            resp: "state",
            respOld: "",
          },
          {
            question: "Nacionalidade",
            resp: "nationality",
            respOld: "",
          },
        ],
      },
      {
        title: "Dados bancários",
        idStep: 3,
        type: "info",
        foreign: null,
        questions: [
          {
            question: "Perfil da conta",
            resp: "profileAccountBank",
            respOld: "",
          },
          {
            question: "CPF ou CNPJ da conta",
            resp: "cpfCnpj",
            respOld: "",
          },
          {
            question: "Nome do favorecido",
            resp: "nameCreditCard",
            respOld: "",
          },
          {
            question: "Nome do seu banco",
            resp: "bankName",
            respOld: "",
          },
          {
            question: "Número da sua agência sem o dígito",
            resp: "bankAgency",
            respOld: "",
          },
          {
            question: "Número da conta sem dígito",
            resp: "bankNumber",
            respOld: "",
          },
          {
            question: "Dígito da conta",
            resp: "bankDigit",
            respOld: "",
          },
          {
            question: "Tipo de conta",
            resp: "typeAccount",
            respOld: "",
          },
          {
            question: "Chave PIX",
            resp: "keyPix",
            respOld: "",
          },
        ],
      },
      {
        title: "Documentos pessoais",
        idStep: 4,
        type: "doc",
        foreign: "brasileiro",
        questions: [
          {
            question: "RG ou CNH (frente)",
            resp: "docFrente",
            id: 6,
          },
          {
            question: "RG ou CNH (verso)",
            resp: "docVerso",
            id: 7,
          },
          {
            question: "CPF",
            resp: "fotoRgCnh",
            id: 5,
          },
          {
            question: "Foto selfie (sua) segurando um documento com foto",
            resp: "docSelfie",
            id: 12,
          },
          {
            question: "Comprovante de residência",
            resp: "comprResid",
            id: 8,
          },
          {
            question: "Foto do cabeçalho do extrato bancário",
            resp: "extratoBancario",
            id: 9,
          },
        ],
      },
      {
        title: "Documentos pessoais",
        idStep: 4,
        type: "doc",
        foreign: "estrangeiro",
        questions: [
          {
            question: "Tipo de documento de identificação",
            resp: "typeDocument",
            id: null,
          },
          {
            question: "Número do documento",
            resp: "numDocumentForeign",
            id: null,
          },
          {
            question: "RNM ou Protocolo",
            resp: "docRNM",
            id: 15,
          },
          {
            question: "Comprovante de residência",
            resp: "comprResid",
            id: 8,
          },
          {
            question: "Extrato bancário",
            resp: "extratoBancario",
            id: 9,
          },
          {
            question: "Foto selfie",
            resp: "docSelfie",
            id: 12,
          },
          {
            question: "Foto do seu negócio ou atividade produtiva",
            resp: "docRefugeProtocol",
            id: 14,
          },
        ],
      },
    ],
  });

  const setSolicitationData = (data) => {
    setDataSolicitation(data);
  };

  const setDataInfo = (data) => {
    setInfoSolicitation({ ...infoSolicitation, ...data });
  };

  return (
    <DataSolicitationContext.Provider
      value={{
        dataSolicitation,
        setSolicitationData,
        setDataInfo,
        infoSolicitation,
        questionPF,
        questionPJ,
      }}
    >
      {children}
    </DataSolicitationContext.Provider>
  );
}

export { DataSolicitationProvider, DataSolicitationContext };
