import React from 'react';

const withSingleProvider = (Provider) => (providerProps) => (Component) => {
    return componentProps => (
        <Provider {...providerProps}>
            <Component {...componentProps} />
        </Provider>
    );
};

export const withProviders = (providers) => (providerProps) => (Component) => {
    return providers.reduce((Component, Provider) => withSingleProvider(Provider)(providerProps)(Component), Component);
};

