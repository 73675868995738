import * as Services from "services";
import Utils from "utils";
import { message } from "antd";

// import * as Actions from "./Theme";

export const listContent = async (endpoint) => {
  const result = await Services.defaultService.getList(endpoint);

  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};

export const listContentActives = async (endpoint) => {
  const result = await Services.defaultService.getListActives(endpoint);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};

export const getContentById = async (endpoint = "", id = 0) => {
  const result = await Services.defaultService.getContent(endpoint, id);
  if (result.error)
    Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
  return result;
};

export const getContentByIdModal = async (endpoint = "", id = 0) => {
  const result = await Services.defaultService.getContentModal(endpoint, id);
  if (result.error)
    Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
  return result;
};

export const submitContent = async (
  endpoint = "",
  content = [],
  update = false
) => {
  let result;
  if (!update) {
    result = await Services.defaultService.insertContent(endpoint, content);
  } else {
    result = await Services.defaultService.updateContent(endpoint, content);
  }
  if (!result.error) {
    Utils.feedback("success", "SUCESSO", "Registro salvo com sucesso");
    return true;
  } else {
    if (result.message === "matricula") {
      Utils.feedback(
        "error",
        "Alerta",
        `Este número de matrícula já está registrado`
      );
    } else if (result.message === "documento") {
      Utils.feedback(
        "error",
        "Alerta",
        `Este número de CPF já está registrado`
      );
    } else if (result.message === "email") {
      Utils.feedback("error", "Alerta", `Este email já está registrado`);
    }

    return false;
  }
};

//INTERESTS

export const listContentInterests = async (endpoint) => {
  const result = await Services.defaultService.getList(endpoint);

  if (!result.error) {
    return result.result;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};

export const submitContentInterests = async (
  endpoint = "",
  content = [],
  update = false
) => {
  let result;
  if (!update) {
    result = await Services.defaultService.insertContent(endpoint, content);

    if (!result.error) {
      Utils.feedback("success", "SUCESSO", "Registro salvo com sucesso");
    } else {
      Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
    }

    return result;
  } else {
    result = await Services.defaultService.updateContent(endpoint, content);
    if (!result.error) {
      Utils.feedback("success", "SUCESSO", "Registro salvo com sucesso");
    } else {
      Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
    }

    return result;
  }
};

export const perfilByInterests = async (id) => {
  const result = await Services.defaultService.listPerfilByInterests(id);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};

export const optionsByInterests = async (id) => {
  const result = await Services.defaultService.listOptionsByInterests(id);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};

//FIM

export const submitContentModal = async (
  endpoint = "",
  content = [],
  update = false
) => {
  let result;
  if (!update) {
    result = await Services.defaultService.insertContent(endpoint, content);

    if (!result.error) {
      Utils.feedback("success", "SUCESSO", "Registro salvo com sucesso");
    } else {
      Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
    }

    return result;
  } else {
    result = await Services.defaultService.updateContent(endpoint, content);

    if (!result.error) {
      Utils.feedback("success", "SUCESSO", "Registro atualizado com sucesso");
    } else {
      Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
    }

    return result;
  }
};

export const submitContentAllModal = async (endpoint = "", content = []) => {
  let result;

  result = await Services.defaultService.insertContentAllModal(
    endpoint,
    content
  );

  if (!result.error) {
    Utils.feedback("success", "SUCESSO", "Registro salvo com sucesso");
  } else {
    Utils.feedback("error", "Alerta", "Houve uma falha, tente novamente!");
  }

  return result;
};

export const updateContent = async (endpoint, content) => {
  const result = await Services.defaultService.updateStatus(endpoint, content);
  if (!result.error) {
    message.success({ content: `Status atualizado`, duration: 2 });
    return true;
  } else {
    message.error({
      content: `Houve uma falha, tente novamente!`,
      duration: 2,
    });
    return false;
  }
};

export const deleteContent = async (endpoint, id) => {
  const result = await Services.defaultService.deleteContent(endpoint, id);
  if (!result.error) {
    message.success({ content: `Registro removido com sucesso`, duration: 2 });
    return true;
  } else {
    message.error({
      content: `Houve uma falha, tente novamente!`,
      duration: 2,
    });
    return false;
  }
};

export const getFiles = async (type, id) => {
  const result = await Services.defaultService.getFiles(type, id);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento dos arquivos.`,
      duration: 2,
    });
    return [];
  }
};

export const removeFiles = async (id) => {
  const result = await Services.defaultService.removeFiles(id);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento dos arquivos.`,
      duration: 2,
    });
    return [];
  }
};

export const changePassword = async (type, data) => {
  const result = await Services.defaultService.changePassword(type, data);
  if (!result.error) {
    message.success({ content: `Senha atualizada com sucesso!`, duration: 2 });
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha, tente novamente!`,
      duration: 2,
    });
    return [];
  }
};

export const resetPassword = async (type, data) => {
  const result = await Services.defaultService.resetPassword(type, data);
  if (!result.error) {
    //message.success({content: `Senha enviada com sucesso!`, duration: 2});
    return "success";
  } else {
    message.error({
      content: `Houve uma falha, tente novamente!`,
      duration: 2,
    });
    return [];
  }
};
export const listModal = async (endpoint) => {
  const result = await Services.defaultService.getList(endpoint);
  if (!result.error) {
    return result.data;
  } else {
    message.error({
      content: `Houve uma falha no carregamento das informações.`,
      duration: 2,
    });
    return [];
  }
};
