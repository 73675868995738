import { PaginationMeta } from "helpers/PaginationMeta";
import { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { api } from "services";

export function usePagination(initialPage = 1) {
  const [currentPage, setCurrentPage] = useState(initialPage);

  const goToNextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const goToPreviousPage = () => setCurrentPage((prevPage) => prevPage - 1);

  return { currentPage, goToNextPage, goToPreviousPage, setCurrentPage };
}

export function usePaginatedData(key, request, reactQueryOptions) {
  const { currentPage, setCurrentPage } = usePagination(
    request.params?.initialPage
  );

  const fetchPage = useCallback(
    async (page = currentPage) => {
      const response = await api.get(request.url, {
        params: {
          ...request.params,
          page,
          limit: request.limit || 10,
        },
      });
      return response.data;
    },
    [request, currentPage]
  );

  const keyWithPage = [...key, currentPage];

  const query = useQuery(keyWithPage, () => fetchPage(currentPage), {
    ...reactQueryOptions,
    keepPreviousData: true,
  });

  return {
    ...query,
    setCurrentPage,
    data: query.data?.data,
    meta: query.data?.meta ? new PaginationMeta(query.data?.meta) : null,
  };
}
